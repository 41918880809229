<template>
  <div class="app-container" v-loading="loading">
    <el-header class="new-header">
      <div class="new-header-left">部门坐席管理</div>
    </el-header>
    <el-form
      :inline="true"
      :model="searchForm"
      ref="searchForm"
      @keyup.enter.native="onSubmit"
    >
      <el-form-item label="坐席名称:" prop="seatAccountName">
        <el-input
          v-model="searchForm.seatAccountName"
          placeholder="坐席名称"
          size="small"
          clearable
          @clear="searchForm.seatAccountName = null"
        ></el-input>
      </el-form-item>
      <el-form-item label="主叫号码">
        <el-input
          v-model="searchForm.phone"
          placeholder="主叫号码"
          size="small"
          clearable
          @clear="searchForm.phone = null"
        ></el-input>
      </el-form-item>
      <el-form-item label="员工名称:" prop="userName">
        <el-input
          v-model="searchForm.userName"
          placeholder="员工名称"
          size="small"
          clearable
          @clear="searchForm.userName = null"
        ></el-input>
      </el-form-item>
      <el-form-item label="坐席状态" prop="state">
        <el-select
          v-model="searchForm.state"
          clearable
          @clear="searchForm.state = null"
          placeholder="坐席状态"
          size="small"
        >
          <el-option label="启用" :value="1"></el-option>
          <el-option label="停用" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" icon="search" size="small"
          >搜索
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      border
      style="width: 100%"
      ref="list"
      stripe
      size="small"
    >
      <el-table-column
        prop="seatAccountName"
        label="坐席名称"
        align="center"
      ></el-table-column>
      <el-table-column prop="userName" label="员工名称" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.isEdit">
            <el-select
              size="small"
              v-model="scope.row.userId"
              placeholder="选择员工"
              clearable
              @clear="scope.row.userId = null"
            >
              <el-option
                v-for="item in userList"
                :key="item.userId"
                :label="item.userName"
                :value="item.userId"
              ></el-option>
            </el-select>
            <el-button
              type="text"
              size="small"
              @click="handleUpdateUser(scope.row)"
              >保存</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="handleCancelUpdate(scope.row)"
              >取消</el-button
            >
          </div>
          <div v-else>
            {{ scope.row.userName }}
            <el-button
              type="text"
              size="small"
              @click="handleInitUpdate(scope.row)"
              >修改</el-button
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="phone"
        label="主叫号码"
        align="center"
      ></el-table-column>
      <el-table-column prop="stateName" label="状态" align="center" width="200">
        <!--                <template slot-scope="scope">-->
        <!--                    <el-switch-->
        <!--                            v-model="scope.row.state"-->
        <!--                            active-text="启用"-->
        <!--                            inactive-text="停用"-->
        <!--                            :active-value="1"-->
        <!--                            :inactive-value="0"-->
        <!--                            @change="handleChangeSeatState(scope.row)"-->
        <!--                    >-->
        <!--                    </el-switch>-->
        <!--                </template>-->
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-tooltip placement="top" content="查看坐席详情">
            <el-button
              type="text"
              size="small"
              @click="handleViewDetails(scope.row)"
            >
              查看
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageVo.current"
        :page-sizes="[2, 10, 20, 50, 100]"
        :page-size="pageVo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      >
      </el-pagination>
    </div>
    <el-dialog title="坐席详情" :visible.sync="dialogForDetails">
      <el-descriptions class="margin-top" title="" border :column="2">
        <el-descriptions-item label="坐席名称">{{
          seatInfo.seatAccountName
        }}</el-descriptions-item>
        <el-descriptions-item label="员工姓名">{{
          seatInfo.userName
        }}</el-descriptions-item>
        <el-descriptions-item label="坐席主叫号码">{{
          seatInfo.phone
        }}</el-descriptions-item>
        <el-descriptions-item label="总分钟数">{{
          seatInfo.totalMinutes
        }}</el-descriptions-item>
        <el-descriptions-item label="已使用分钟数">{{
          seatInfo.usedMinutes
        }}</el-descriptions-item>
        <el-descriptions-item label="剩余分钟数">{{
          seatInfo.remainMinutes
        }}</el-descriptions-item>
        <el-descriptions-item label="状态">{{
          seatInfo.stateName
        }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>
<script>
import {
  editSeatAccountOfUser,
  getSeatInfoOfMerchant,
  getSeatListOfMerchantDepartmentByPage,
  getUserListNoBindSeatOfMerchant,
  getUserListOfMerchantByPage,
} from "../../api";

export default {
  name: "",
  data() {
    return {
      dialogForDetails: false,
      dialogForModifyUser: false,
      userId: this.$store.getters.userId,
      searchForm: {
        userId: this.$store.getters.userId,
        userName: null,
        state: null,
        phone: null,
        seatAccountName: "",
      },
      userList: [],
      seatInfo: {
        phone: "",
        stateName: "",
        userName: "",
        merchantName: "",
        seatAccountName: "",
        usedMinutes: "",
        totalMinutes: "",
        remainMinutes: "",
      },
      list: [],
      pageVo: {
        current: 1,
        size: 10,
      },
      totalCount: 0,
      loading: false,
      rules: {
        phone: [
          { required: true, message: "请输入用户手机号码", trigger: "blur" },
        ],
        userName: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.loadSeatList();
    // this.loadMerchantUserList()
  },
  methods: {
    async handleInitUpdate(row) {
      this.userList = await getUserListNoBindSeatOfMerchant({
        userId: this.userId,
        seatAccountId: row.seatAccountId,
      });
      this.$set(row, "isEdit", true);
    },
    handleCancelUpdate(row) {
      this.$set(row, "isEdit", false);
    },
    async handleUpdateUser(row) {
      let data = await editSeatAccountOfUser({
        newUserId: row.userId,
        seatAccountId: row.seatAccountId,
      });
      if (data) {
        this.$notify({
          type: "success",
          title: "成功",
          message: "修改绑定员工成功",
        });
      }
      this.$set(row, "isEdit", false);
      this.loadSeatList();
    },
    async loadMerchantUserList() {
      let data = await getUserListOfMerchantByPage({
        current: 1,
        size: 200,
        params: {
          userId: this.$store.getters.userId,
        },
      });
      this.userList = data.records;
    },
    async handleViewDetails(row) {
      if (row.seatAccountId) {
        let data = await getSeatInfoOfMerchant({
          seatAccountId: row.seatAccountId,
        });
        Object.assign(this.seatInfo, data);
        this.dialogForDetails = true;
      }
    },
    handleChangeUserState(row) {
      let param = {
        userId: row.userId,
        state: row.state,
        adminId: this.$store.getters.adminId,
      };
      editUserState(param).then((data) => {
        if (data) {
          let message =
            row.state === 1
              ? `启用用户${row.userName}成功`
              : `停用用户${row.userName}成功`;
          this.$notify({
            type: "success",
            title: "成功",
            message: message,
          });
        }
      });
    },

    onSubmit() {
      this.pageVo.current = 1;
      this.loadSeatList();
    },
    handleSizeChange(val) {
      this.pageVo.size = val;
      this.loadSeatList();
    },
    handleCurrentChange(val) {
      this.pageVo.current = val;
      this.loadSeatList();
    },
    loadSeatList() {
      let params = {
        ...this.pageVo,
        params: this.searchForm,
      };
      this.loading = true;
      getSeatListOfMerchantDepartmentByPage(params).then((data) => {
        console.log(data);
        this.list = data.records;
        this.totalCount = data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss"></style>
